import Layout from './components/Layout';
import TestPage from './components/TestPage';
import HomePage from './components/HomePage';
import Register from './components/Register';
import Login from './components/Login';
import RequireAuth from './components/RequireAuth';

import { Routes, Route } from 'react-router-dom';
import SecureDashBoard from './components/SecureDashBoard';
import SecureSettings from './components/SecureSettings'
import SecureUserManagement from './components/SecureUserManagement';
import SecureFileExplorer from './components/SecureFileExplorer';

import Missing from './components/Missing';



function App() {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/test" element={<TestPage />} />
        <Route path="register" element={<Register />} />
        <Route path="login" element={<Login />} />

        {/* we want to protect these routes */}
        <Route element={<RequireAuth />}>
          <Route path="/dashboard" element={<SecureDashBoard />} />
          <Route path="/settings" element={<SecureSettings />} />
          <Route path="/usermanagement" element={<SecureUserManagement />} />
          <Route path="fileexplorer" element={<SecureFileExplorer />} />
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
        
      </Route>
    </Routes>
  );
}

export default App;