import { useState, useRef, useEffect } from "react";
import UserDropdown from "./subcomponents/UserDropdown";

import HomeHeader from './subcomponents/HomeHeader'
import HomeFooter from './subcomponents/HomeFooter';
import FileExplorerToolbar from './subcomponents/FileExplorerToolbar';
import FileExplorerSidePanel from './subcomponents/FileExplorerSidePanel';
import FileExplorerMain from './subcomponents/FileExplorerMain';
import { FolderContextProvider } from '../context/FolderContextProvider';

import './stylecomponents/fileExplorer.css'

function SecureFileExplorer() {

  const [userAccDropdown, setUserAccDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = (event) => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      return;
    }
    setUserAccDropdown(false);
  };
    
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
      <div className='page'>

        <HomeHeader menuArray={[]}>
          <span className="icon"><img src="/status_icons/search-icon.png" alt="Search"/></span>
          <span className="icon"><img src="/status_icons/language-icon.png" alt="Language"/></span>
          <span className="icon" ref={dropdownRef} onClick={() => setUserAccDropdown(prev=>!prev)}>
            <img src="/status_icons/login-icon.png" alt="Login"/>
            { userAccDropdown && <UserDropdown/>}                   
          </span>
        </HomeHeader>
        
        <main>
          <FolderContextProvider>
            <section className='main'>
              <FileExplorerToolbar/>
              <section className='section6'>
                <FileExplorerSidePanel/>
                <FileExplorerMain/>
              </section>
            </section>
          </FolderContextProvider>          
        </main>

        <HomeFooter/>
      </div>
  );
}

export default SecureFileExplorer;
