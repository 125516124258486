import { useFolderContext } from "../../context/FolderContextProvider";

export default function FileExplorerTiles({isSelect, tileId, isFolder, name}) {

    const {setOpenDirectoryId, setSelectDirectoryId} = useFolderContext();

    const handleDblClick = () => {
        if (isFolder) {
            setOpenDirectoryId(tileId);
            setSelectDirectoryId(null);
        }
    }
    const itemType = isFolder ? '/status_icons/folder_icon.svg' : '/status_icons/file_icon.svg';

    return (
        <div className={isSelect? "items selected": "items"}>
            <div className='tile-top'>
                <img onDoubleClick={handleDblClick} className="tileIcon" id={tileId} src={itemType} alt={isFolder ? 'Folder Icon' : 'File Icon'} width='50px' />
            </div>
            <div className='tile-bottom'>
                <div>{name}</div>
            </div>
        </div>
    )
}
