import { useNavigate, Link } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import useAuth from "../../hooks/useAuth";

const UserDropdown = () => {

    const navigate = useNavigate();
    const logout = useLogout();

    const logOutFunc = async () => {
        navigate('/');
        await logout();
    }

    const { auth } = useAuth();

    return (
        <div className="dropdown-content">
            <div> Hello {auth.usermail} </div>
            <div> <Link to="/fileexplorer">File Manager</Link> </div>
            <div> <Link to="/usermanagement">User Management</Link> </div>
            <div> <Link to="/settings">Settings</Link> </div>
            <div onClick={logOutFunc}>Log Out</div>
        </div>
    )
}

export default UserDropdown
