import { useRef, useState, useEffect } from 'react';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import HomeHeader from './subcomponents/HomeHeader';
import HomeFooter from './subcomponents/HomeFooter';
import './stylecomponents/form.css'

import axios from '../api/axios';
const LOGIN_URL = '/auth';

// const userRoles = {
//   'admin': 123,
//   'designer': 456,
//   'viewer': 789
// }

const Login = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";

    const userRef = useRef();
    const errRef = useRef();

    const [usermail, setUsermail] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [usermail, password])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ usermail, password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const accessToken = response?.data?.accessToken;
            const role = response?.data?.role;
            setAuth({ usermail, password, role, accessToken });
            setUsermail('');
            setPassword('');
            console.log({ usermail, password, role, accessToken });
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (
      <div className="page">
        <HomeHeader menuArray={[]}></HomeHeader>

        <main>

          <section className='section1'>
            <p ref={errRef} className={errMsg ? "errmsg sec-child" : "offscreen sec-child"} >
              <FontAwesomeIcon icon={faInfoCircle} />
              {' ' + errMsg + ' ...'}
            </p>
          </section>

          <section className='section2'>
            
            <h2 className='sec-child'>Log In</h2>
            <form className='sec-child' onSubmit={handleSubmit}>

              <input
                type="email"
                id="email"
                placeholder="Email"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUsermail(e.target.value)}
                value={usermail}
                required
              />

              <input
                type="password"
                id="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />

              <button>Sign In</button>
            </form>

            <div className='smallfont10 sec-child'>
              <input type="checkbox" className="box" id='rememberbox'/>
              <label htmlFor='rememberbox'>Remember me</label>
            </div>

            <div className='smallfont10 sec-child'>
              <div className='inline'>
                <span >Need an Account?</span> 
                <span ><Link to="/register">Register</Link></span>              
              </div>
              <div className='inline'>
                <span >Go to HomePage?</span>
                <span ><Link to="/">Home</Link></span>
              </div>
            </div>

            

          </section>

        </main>

        <HomeFooter />
      </div>
    );
}

export default Login
