function generateNewId() {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base-36 string
  const randomString = Math.random().toString(36).slice(2, 11); // Generate random string
  let id = timestamp + randomString;
  id = id.slice(5,13);

  return id;
}

function findObjectById(tree, targetId) {
    if (tree.id === targetId) {
      return tree;
    }
  
    if (Array.isArray(tree.items)) {
      for (const item of tree.items) {
        const result = findObjectById(item, targetId);
        if (result) {
          return result;
        }
      }
    }
    return null; // ID not found in the tree
}

function findPathById(tree, targetId, currentPath = '') {
    // Check if the current node is the target
    if (tree.id === targetId) {
      return currentPath + `/${tree.name}`;
    }
  
    // Check if the current node has items
    if (Array.isArray(tree.items)) {
      // Iterate through the items
      for (const item of tree.items) {
        // Recursive call to find the target in the subtree
        const path = findPathById(item, targetId, `${currentPath}/${tree.name}`);
  
        // If the target is found in the subtree, return the path
        if (path !== null) {
          return path;
        }
      }
    }
  
    // If the ID is not found in the tree, return null
    return null;
}

function isNameInChildNodes(tree, parentId, nameToCheck) {
    const parentNode = findObjectById(tree, parentId);
  
    if (parentNode && Array.isArray(parentNode.items)) {
      return parentNode.items.some((item) => item.name === nameToCheck);
    }
  
    return false;
}

function isIdInChildNodes(tree, parentId, idToCheck) {
    const parentNode = findObjectById(tree, parentId);
  
    if (parentNode && Array.isArray(parentNode.items)) {
      return parentNode.items.some((item) => item.id === idToCheck);
    }
  
    return false;
}

function findParentId(tree, childId) {
    for (const node of tree.items) {
        if (node.id === childId) {
            return tree.id;
        }
        if (node.items.length > 0) {
            const foundParentId = findParentId(node, childId);
            if (foundParentId !== null) {
                return foundParentId;
            }
        }
    }
    return null;
}

function getAllNodeIds(tree) {
    let nodeIds = [];
  
    function traverse(node) {
      nodeIds.push(node.id);
      if (node.items && node.items.length > 0) {
        node.items.forEach(item => traverse(item));
      }
    }
  
    traverse(tree);
    return nodeIds;
}

  function insertNodeById(tree, parentId, newNode) {
    // Find the parent node
    const parentNode = findObjectById(tree, parentId);
  
    if (parentNode) {
      // Add the new node to the parent's items
      parentNode.items.push(newNode);
      return tree; // Return the modified tree
    } else {
      // Handle the case where the parent node is not found
      console.error(`Parent node with ID ${parentId} not found.`);
      return tree; // Return the original tree
    }
  }

  function deleteNodeById(tree, parentId, targetId) {
    // Find the parent node
    const parentNode = findObjectById(tree, parentId);
  
    if (parentNode) {
      // Find the index of the target node within its parent's items
      const targetIndex = parentNode.items.findIndex((item) => item.id === targetId);
  
      if (targetIndex !== -1) {
        // Remove the target node from its parent's items
        parentNode.items.splice(targetIndex, 1);
      } else {
        console.error(`Node with ID ${targetId} not found in its parent's items.`);
      }
    } else {
      console.error(`Parent node for ID ${targetId} not found.`);
    }
  
    return tree;
  }

  function nameToPaste(tree, targetId, parentIdToAttach) {

    // Find the node to be cut
    const targetNode = findObjectById(tree, targetId);
  
    if (!targetNode) {
      console.error(`Node with ID ${targetId} not found.`);
      return tree; // Return the original tree if node to cut is not found
    }
  
    // Find the parent node to attach the cut node
    const parentNodeToAttach = findObjectById(tree, parentIdToAttach);
  
    if (!parentNodeToAttach) {
      console.error(`Parent node with ID ${parentIdToAttach} not found.`);
      return tree; // Return the original tree if parent node to attach is not found
    }

    // Check if the node's name conflicts with existing direct children
    const existingNames = parentNodeToAttach.items.map(item => item.name);
    let newName = targetNode.name;
    let copyCounter = 1;
    
    let parts = targetNode.name.split('.');
      if (parts.length > 1) {
        while (existingNames.includes(newName)) {
          newName = `${parts.slice(0,-1).join('.')}copy${copyCounter}.${parts[parts.length - 1]}`;
          copyCounter++;
        }        
      }
      else {
        while (existingNames.includes(newName)) {
          newName = `${targetNode.name}copy${copyCounter}`;
          copyCounter++;
        }
      }

    return {copyCounter,newName};
  }

  function cutPasteNode(copyCounter, newName, tree, nodeIdToCut, currentParentId, parentIdToAttach) {
    // Find the node to be cut
    const nodeToCut = findObjectById(tree, nodeIdToCut);
  
    if (!nodeToCut) {
      console.error(`Node with ID ${nodeIdToCut} not found.`);
      return tree; // Return the original tree if node to cut is not found
    }
  
    // Find the parent node to attach the cut node
    const parentNodeToAttach = findObjectById(tree, parentIdToAttach);
  
    if (!parentNodeToAttach) {
      console.error(`Parent node with ID ${parentIdToAttach} not found.`);
      return tree; // Return the original tree if parent node to attach is not found
    }
  
    // Remove the node to cut from its current parent's items array
    const currentParent = findObjectById(tree, currentParentId);
    if (currentParent) {
      const indexToRemove = currentParent.items.findIndex(item => item.id === nodeIdToCut);
      if (indexToRemove !== -1) {
        currentParent.items.splice(indexToRemove, 1);
      }
    }

    if (copyCounter!==1) {nodeToCut.name = newName;}    
  
    // Add the node to cut as a child to the parent node to attach
    parentNodeToAttach.items.push(nodeToCut);
  
    return tree;
  }
  
  function copyPasteNodeBE(tree, nodeIdToCopy, parentIdToAttach) {
    // Find the node to be copied
    const nodeToCopy = findObjectById(tree, nodeIdToCopy);
  
    if (!nodeToCopy) {
      console.error(`Node with ID ${nodeIdToCopy} not found.`);
      return Error('nothing copied'); // Return the original tree if node to copy is not found
    }
  
    // Create a deep copy of the node to copy and update IDs
    const copiedNode = deepCopyAndUpdateIds(nodeToCopy);

    // Find the parent node to attach the copied node
    const parentNodeToAttach = findObjectById(tree, parentIdToAttach);
  
    if (!parentNodeToAttach) {
      console.error(`Parent node with ID ${parentIdToAttach} not found.`);
      return Error('invalid paste'); // Return the original tree if parent node to attach is not found
    }
  
    // Check if the node's name conflicts with existing direct children
    const existingNames = parentNodeToAttach.items.map(item => item.name);
    let newName = copiedNode.name;
    let copyCounter = 1;
    
    let parts = copiedNode.name.split('.');
      if (parts.length > 1) {
        while (existingNames.includes(newName)) {
          newName = `${parts.slice(0,-1).join('.')}copy${copyCounter}.${parts[parts.length - 1]}`;
          copyCounter++;
        }        
      }
      else {
        while (existingNames.includes(newName)) {
          newName = `${copiedNode.name}copy${copyCounter}`;
          copyCounter++;
        }
      }

    copiedNode.name = newName;  

    let newArray = treeToNodeArray(copiedNode, parentIdToAttach);
  
    return {newArray, copiedNode};
  }

  function copyPasteNode(tree, copiedNode, parentIdToAttach) {
  
    // Find the parent node to attach the copied node
    const parentNodeToAttach = findObjectById(tree, parentIdToAttach);
  
    if (!parentNodeToAttach) {
      console.error(`Parent node with ID ${parentIdToAttach} not found.`);
      return tree; // Return the original tree if parent node to attach is not found
    }

    // Add the copied node as a child to the parent node to attach
    parentNodeToAttach.items.push(copiedNode);
  
    return tree;
  }
  
  function deepCopyAndUpdateIds(node) {
    const copiedNode = { ...node }; // Shallow copy of the node
  
    // Generate a new ID for the copied node
    copiedNode.id = generateNewId();
  
    // Recursively copy and update IDs for child nodes
    if (copiedNode.items && copiedNode.items.length > 0) {
      copiedNode.items = copiedNode.items.map(childNode => deepCopyAndUpdateIds(childNode));
    }
  
    return copiedNode;
  }

  function deepCopy(node) {
    const copiedNode = { ...node }; // Shallow copy of the node
  
    // Recursively copy and update IDs for child nodes
    if (copiedNode.items && copiedNode.items.length > 0) {
      copiedNode.items = copiedNode.items.map(childNode => deepCopy(childNode));
    }
  
    return copiedNode;
  }

  function renameNode(tree, newNodeName, targetNodeId, targetNodeParentId) {
    // Find the target node
    const parentNode = findObjectById(tree, targetNodeParentId);

    if (!parentNode) {
        console.error(`Parent node with ID ${targetNodeParentId} not found.`);
        return tree; // Return the original tree if parent node is not found
    }

    const targetNode = parentNode.items.find(item => item.id === targetNodeId);

    if (!targetNode) {
        console.error(`Node with ID ${targetNodeId} not found.`);
        return tree; // Return the original tree if target node is not found
    }

    // Rename the target node
    targetNode.name = newNodeName;

    return tree;
  }

function treeToNodeArray(tree, parentId) {
  let nodeArray = [];
  
  // Add the root node itself to the array
  if (parentId) {
      const { id, name, isFolder } = tree;
      nodeArray.push({ id, parentId, name, isFolder });
  }
  
  // Process the children of the tree
  tree.items.forEach(item => {
    const { id, name, isFolder, items } = item;
    nodeArray.push({ id, parentId: tree.id, name, isFolder });
    
    if (isFolder && items.length > 0) {
      nodeArray = nodeArray.concat(treeToNodeArray(item));
    }
  });
  
  return nodeArray;
}


// following function to be rechecked
function treeToNodeArrayChildren(tree, parentId = 'root') {
  let nodeArray = [];
  
  tree.items.forEach(item => {
    const { id, name, isFolder, items } = item;
    nodeArray.push({ id, parentId, name, isFolder });
    
    if (isFolder && items.length > 0) {
      nodeArray = nodeArray.concat(treeToNodeArray(item, id));
    }
  });
  
  return nodeArray;
}

function nodeArrayToTree(nodeArray) {
  const map = {}; // Map to store nodes by their ID
  const root = { id: 'root', name: 'root', isFolder: true, items: [] };

  // Populate the map
  nodeArray.forEach(node => {
    const { node_id, name, isfolder } = node;
    map[node_id] = { id:node_id, name, isFolder:isfolder, items: [] };
  });

  // Add nodes to their respective parent's items array
  nodeArray.forEach(node => {
    const { node_id, parent_id } = node;
    if (parent_id !== 'root') {
      map[parent_id]?.items.push(map[node_id]);
    } else {
      root.items.push(map[node_id]);
    }
  });

  return root;
}

const updateNodeTables = async (axiosObject, method, url, data) => {
  try {
      const response = await axiosObject({
          method: method, // HTTP method (GET, POST, PUT, DELETE)
          url: url, // API endpoint URL
          data: data, // Request body data
      });
      return response.data; // Return response data
  } catch (error) {
      throw error; // Throw error for handling in the caller function
  }
};


export {generateNewId, findObjectById, findPathById, isNameInChildNodes, isIdInChildNodes, findParentId, 
  getAllNodeIds, insertNodeById, deleteNodeById, nameToPaste, cutPasteNode, copyPasteNodeBE, copyPasteNode, 
  deepCopy, renameNode, treeToNodeArray, updateNodeTables, nodeArrayToTree, treeToNodeArrayChildren}