import { useState, useRef, useEffect, useCallback } from "react";
import { useFolderContext } from "../../context/FolderContextProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { deepCopy, generateNewId, insertNodeById, isIdInChildNodes, isNameInChildNodes, updateNodeTables } from "./fileExplorer_func";

const TREE_URL = '/treenodes';

export default function FileExplorerNewTile({isFolder}) {

    const {openDirectoryId, explorerJson, handleExplorerStateChange, isNewFolder, isNewPresent, setIsNewPresent } = useFolderContext();

    const [newName, setNewName] = useState('untitled');
    const axiosPrivate = useAxiosPrivate();
    const newRef = useRef();
    const newId = useRef();

    const createNew = useCallback(() => {
        newId.current = generateNewId();
        if (!isIdInChildNodes(explorerJson, openDirectoryId, newId.current)) {
            try {
                let newNode = {
                    id: newId.current,
                    name: newName,
                    isFolder: isNewFolder,
                    items: []
                };
                let {items, ...newNodeCopy} = newNode;
                newNodeCopy.parentId = openDirectoryId;
                console.log(newNodeCopy);
                updateNodeTables(axiosPrivate, 'post', TREE_URL, {isArray:false, nodeData:newNodeCopy}).then(
                    function(value) {                        
                        let newTree = deepCopy(insertNodeById(explorerJson, openDirectoryId, newNode));
                        handleExplorerStateChange(newTree);
                        setIsNewPresent(false);
                        console.log(value);
                    },
                    function(error) {
                        if (error.response?.status === 401) {
                            createNew();//duplicate node exists
                        }
                        console.error(error)}
                );
                
            } catch (err) {
                console.error(err)
            }                    
        }
        else {
            createNew();
        }              
    },[explorerJson, isNewFolder, newId, newName, openDirectoryId, axiosPrivate, setIsNewPresent, handleExplorerStateChange])


    const createNewFolderFunc2 = useCallback((e) => {
        if (isNewPresent && e.target.className === 'section8' && e.target.classList[0] !== 'new') {
            if (! isNameInChildNodes(explorerJson, openDirectoryId, newName)) {
                createNew();                     
            }
            else {alert('same name exists !')}            
        }

        else if(isNewPresent && e.target.id !== 'tool1' && e.target.classList[0] !== 'new') {
            setIsNewPresent(false);
        }

        else if(isNewPresent) {
            newRef.current.focus();
        }

    },[isNewPresent, setIsNewPresent, createNew, explorerJson, openDirectoryId, newName])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (! isNameInChildNodes(explorerJson, openDirectoryId, newName)) {
                createNew();                     
            }
            else {alert('same name exists !')}
          }
    }

    const itemType = isFolder ? '/status_icons/folder_icon.svg' : '/status_icons/file_icon.svg';
    
    useEffect(() => {
        // Focus the input element when the component mounts
        newRef.current.focus();
    
        // Select the text in the input
        newRef.current.select();
    }, []);

    useEffect(() => {
        document.addEventListener('click', createNewFolderFunc2);
      
        return () => {
          document.removeEventListener('click', createNewFolderFunc2);
        }
      }, [createNewFolderFunc2])



    
    return (
        <div className="new items">
            <div className='new tile-top'>
                <img className="new tileIcon" src={itemType} alt={isFolder ? 'Folder Icon' : 'File Icon'} width='50px' />
            </div>
            <div className='new tile-bottom'>
                <div>
                    <input
                    className="new"
                    id="new"
                    type="text"
                    ref={newRef}
                    onChange={(e) => setNewName(e.target.value)}
                    value={newName}
                    required
                    onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
        </div>
    )
}
