import { createContext, useCallback, useContext, useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import explorer from '../components/data/explorerData';
import { deepCopy, nodeArrayToTree, updateNodeTables} from "../components/subcomponents/fileExplorer_func";

const TREE_URL = '/treenodes'

const FolderContext = createContext({});

export function useFolderContext() {return useContext(FolderContext)};

export function FolderContextProvider({children}) {
    
    const axiosPrivate = useAxiosPrivate();
    const [openDirectoryId, setOpenDirectoryId] = useState('root');
    const [selectDirectoryId, setSelectDirectoryId] = useState(null);
    const [explorerJson, setExplorerJson] = useState(explorer);

    const [treeHistory, setTreeHistory] = useState([deepCopy(explorer)]);
    const [treeHistoryIndex, setTreeHistoryIndex] = useState(0);

    const [isNewPresent, setIsNewPresent] = useState(false);
    const [isNewFolder, setIsNewFolder] = useState(false);
    const [renameId, setRenameId] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getExplorerArray = async () => {
            try {
                updateNodeTables(axiosPrivate, 'get', TREE_URL, null).then(
                    function(value) {
                      console.log(value)
                        if (isMounted){
                            
                            let newExplorer = nodeArrayToTree(value);
                            console.log(newExplorer);
                            setExplorerJson(newExplorer);
                            setTreeHistory([deepCopy(newExplorer)]);
                        }
                    },
                    function(error) {
                      throw Error(error);
                    }
                )
            } catch (err) {
                console.error(err);
            }
        }

        getExplorerArray();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate]);

    // Function to handle state changes and manage treeHistory
    const handleExplorerStateChange = useCallback((newState) => {
        setExplorerJson(newState);
        const newHistory = [...treeHistory.slice(Math.max(treeHistoryIndex - 2, 0), treeHistoryIndex + 1), deepCopy(newState)];
        setTreeHistory(newHistory);
        setTreeHistoryIndex(newHistory.length - 1); // Update treeHistory index
    },[treeHistory, treeHistoryIndex]);

    return (
        <FolderContext.Provider value={{
            openDirectoryId, setOpenDirectoryId, selectDirectoryId, setSelectDirectoryId,
            explorerJson, setExplorerJson, isNewPresent, setIsNewPresent, isNewFolder, 
            setIsNewFolder, renameId, setRenameId, handleExplorerStateChange,
            treeHistory, treeHistoryIndex,setTreeHistory, setTreeHistoryIndex}} >
            {children}
        </FolderContext.Provider>
    )
}