import { useMemo } from "react";

import { useFolderContext } from "../../context/FolderContextProvider";
import FileExplorerTiles from "./FileExplorerTiles";
import FileExplorerNewTile from "./FileExplorerNewTile";

import { findObjectById } from "./fileExplorer_func";
import FileExplorerRenameTile from "./FileExplorerRenameTile";

const FileExplorerMain = () => {

  const {explorerJson, openDirectoryId, selectDirectoryId, setSelectDirectoryId, renameId, isNewPresent, isNewFolder} = useFolderContext();

  const handleClick = (e) => {

    if (e.target.className === 'tileIcon') {
      setSelectDirectoryId(e.target.id);
    }
    else {setSelectDirectoryId(null)}
  }

  let openDirectoryItem = useMemo(() => {
    return findObjectById(explorerJson, openDirectoryId);
  }, [explorerJson, openDirectoryId]);

  let htmlTiles = useMemo(() => {
    return openDirectoryItem?.items.map(item => {
      let isSelect = selectDirectoryId===item.id;
      let isRename = renameId===item.id;
      if (!(isSelect || isRename)) {
        return <FileExplorerTiles key={item.id} isSelect={false} tileId={item.id} isFolder={item.isFolder} name={item.name}/>
      }
      else if (isRename) {
        return <FileExplorerRenameTile key={item.id} isFolder={true} defaultname={item.name}/>
        
      }
      else {
        return <FileExplorerTiles key={item.id} isSelect={true} tileId={item.id} isFolder={item.isFolder} name={item.name}/>
      }
    })
  }, [openDirectoryItem, selectDirectoryId, renameId])
  
  return (
    <section className='section8' onClick={handleClick}>  
      {htmlTiles}
      {isNewPresent && <FileExplorerNewTile isFolder={isNewFolder}/>}
    </section>
  )
}

export default FileExplorerMain