import { useState, useEffect } from "react";
import { useFolderContext } from "../../context/FolderContextProvider"
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { copyPasteNode, cutPasteNode, deleteNodeById, findParentId, findPathById, deepCopy, 
  updateNodeTables, getAllNodeIds, findObjectById, nameToPaste, copyPasteNodeBE, nodeArrayToTree } from "./fileExplorer_func";

const TREE_URL = '/treenodes';

const FileExplorerToolbar = () => {

  const {openDirectoryId, setOpenDirectoryId, selectDirectoryId, setRenameId, explorerJson, setExplorerJson, 
    handleExplorerStateChange, setSelectDirectoryId, setIsNewPresent, setIsNewFolder,
    treeHistory, treeHistoryIndex, setTreeHistory, setTreeHistoryIndex} = useFolderContext();

  const axiosPrivate = useAxiosPrivate();
  const [pathValue, setPathValue] = useState('');
  const [clipId, setClipId] = useState(null);
  const [clipParentId, setClipParentID] = useState(null);

  useEffect(() => {
    setPathValue(() => {
      let path = findPathById(explorerJson, openDirectoryId);
      if(path){return path}
      else {return ''}
    })
  }, [explorerJson, openDirectoryId])

  const createNewFolderFunc1 = (e) => {
    e.stopPropagation();
    setIsNewFolder(true);
    setIsNewPresent(true);
    setSelectDirectoryId(null);
    setRenameId(null);
  }

  const createNewFileFunc = () => {
    console.log('createnewfile');
  }

  const renameFunc1 = (e) => {
    e.stopPropagation();
    if (selectDirectoryId) {
      setRenameId(selectDirectoryId);
      setSelectDirectoryId(null);
    }
  }

  const cutFunc = () => {
    if (selectDirectoryId) {
      setClipId(selectDirectoryId);
      setClipParentID(openDirectoryId);
    }   
  }

  const copyFunc = () => {
    if (selectDirectoryId) {
      setClipId(selectDirectoryId);
      setClipParentID(null);
    }   
  }            

  const pasteFunc = () => {
    if (clipId) {
      function pasteFinish (tree) {
        handleExplorerStateChange(deepCopy(tree));      
        setClipId(null);
        setClipParentID(null);
        setSelectDirectoryId(null);
      }
      if (clipParentId) {
        try {
          let {copyCounter,newName} = nameToPaste(explorerJson, clipId, openDirectoryId);
          let nodeCut = {id: clipId, parentId: openDirectoryId, name: (copyCounter!==1)?newName:undefined};
          updateNodeTables(axiosPrivate, 'put', TREE_URL, nodeCut).then(
              function(value) {
                let newTree = cutPasteNode(copyCounter, newName, explorerJson, clipId, clipParentId, openDirectoryId)
                pasteFinish(newTree);
                console.log(value);
              },
              function(error) {
                console.error(error);
              }
          )
      } catch (error) {
          console.error(error);
      }}
      else {
        try {
          let {newArray, copiedNode} = copyPasteNodeBE(explorerJson, clipId, openDirectoryId);
          updateNodeTables(axiosPrivate, 'post', TREE_URL, {isArray:true, nodeData:newArray}).then(
              function(value) {
                let newTree = copyPasteNode(explorerJson, copiedNode, openDirectoryId);
                pasteFinish(newTree);
                console.log(value);
              },
              function(error) {
                console.error(error);
              }
          )
      } catch (error) {
          console.error(error);
      }}      
    }   
  }

  const deleteFunc = () => {
    if (selectDirectoryId) {
      try {
        let idArray = getAllNodeIds(findObjectById(explorerJson, selectDirectoryId))
        console.log(idArray);
        updateNodeTables(axiosPrivate, 'delete', TREE_URL, idArray).then(
          function(value) {
            let newTree = deleteNodeById(explorerJson, openDirectoryId, selectDirectoryId);
            handleExplorerStateChange(deepCopy(newTree));
            setSelectDirectoryId(null);
            console.log(value)
          },
          function(error) {console.error(error.message)}
      );        
      } catch (error) {
        console.log(error);
      }      
    }   
  }

  const undoFunc = (e) => {
    e.stopPropagation();
    if (treeHistoryIndex > 0) {
      setTreeHistoryIndex((prevIndex) => prevIndex - 1);
      setExplorerJson(deepCopy(treeHistory[treeHistoryIndex - 1]));
    }
    setSelectDirectoryId(null);
    setRenameId(null);
  }

  const redoFunc = (e) => {
    e.stopPropagation();
    if (treeHistoryIndex < treeHistory.length - 1) {
      setTreeHistoryIndex((prevIndex) => prevIndex + 1);
      setExplorerJson(deepCopy(treeHistory[treeHistoryIndex + 1]));
    }
    setSelectDirectoryId(null);
    setRenameId(null);
  }

  const parentFunc = () => {
    setOpenDirectoryId((prevId) => {
      return (
        (prevId === 'root') ?
        prevId :
        findParentId(explorerJson, prevId) ?? prevId
        )
    })
    setSelectDirectoryId(null);
    setRenameId(null);
  }

  const refreshFunc = async () => {
    console.log('refreshFunc');
    if (window.confirm('history will be lost !')) {
      try {
        updateNodeTables(axiosPrivate, 'get', TREE_URL, null).then(
            function(value) {
              console.log(value)              
              let newExplorer = nodeArrayToTree(value);
              setExplorerJson(newExplorer);
              setTreeHistory([deepCopy(newExplorer)]);              
            },
            function(error) {
              throw Error(error);
            }
        )
      } catch (err) {
          console.error(err);
      }
    }
  }

    return (
        <section className='section5'>
              <section className='tools'>
                <img id="tool1" onClick={createNewFolderFunc1} src='/status_icons/create_new_folder.svg' alt='new folder'/>
                <img id="tool2" onClick={createNewFileFunc} src='/status_icons/create_new_file.svg' alt='new file'/>
                <img id="tool3" onClick={renameFunc1} src='/status_icons/edit.svg' alt='edit'/>
                <img id="tool4" onClick={cutFunc} src='/status_icons/cut.svg' alt='cut'/>
                <img id="tool5" onClick={copyFunc} src='/status_icons/file_copy.svg' alt='copy'/>
                <img id="tool6" onClick={pasteFunc} src='/status_icons/paste.svg' alt='paste'/>
                <img id="tool7" onClick={deleteFunc} src='/status_icons/delete.svg' alt='delete'/>
                <img id="tool8" onClick={undoFunc} src='/status_icons/undo.svg' alt='undo'/>
                <img id="tool9" onClick={redoFunc} src='/status_icons/redo.svg' alt='redo'/>
                <img id="tool10" onClick={parentFunc} src='/status_icons/folder_up.svg' alt='parent'/>
                <img id="tool11" onClick={refreshFunc}src='/status_icons/refresh.svg' alt='refresh'/>
              </section>
              <section className='itempath'>
                <span>PATH :</span>
                <input
                  type="text"
                  id="pathvalue"
                  onChange={(e) => setPathValue(e.target.value)}
                  value={pathValue}
                  disabled
                />
              </section>
        </section>
    )
}

export default FileExplorerToolbar