import { useState, useEffect } from "react";
import { useFolderContext } from "../../context/FolderContextProvider";

export default function Directory({folderJson}) {

    const {openDirectoryId, setOpenDirectoryId, setSelectDirectoryId} = useFolderContext();
    
    const [expand, setExpand] = useState(()=>{return folderJson.id === 'root' ? true : false});

    const handleClick = () => {
        
        if (folderJson.isFolder) {
            if (folderJson.id !== 'root') {setExpand(prev => !prev)};
            setOpenDirectoryId(prevId => {
                if (folderJson.id !== prevId) {
                    setSelectDirectoryId(null);
                    return folderJson.id;
                }
                return prevId;
            })
        }
    }

    useEffect(() => { (openDirectoryId===folderJson.id) && setExpand(true)}, [openDirectoryId, folderJson])
    

  return (
    <div style={{marginTop: 5, cursor: "pointer"}}>
        
        <div onClick={handleClick} className="directoryTab" id={folderJson.id}>
            <span> { (folderJson.isFolder? "📁  " : "📄  ") + folderJson.name}</span>
        </div>

        <div style={{ display: expand ? "block" : "none", paddingLeft: 8 }}>
            {folderJson.isFolder && folderJson.items.map(elem => <Directory key={elem.id} folderJson={elem}/>)}
        </div>

    </div>
  )
}
