import '../stylecomponents/homeFooter.css'

const HomeFooter = () => {
    return (
        <footer>
            <span>Standard Agreement- T&C</span>
            <span>Privacy</span>
        </footer>
    )
}

export default HomeFooter