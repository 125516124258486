import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Users from './subcomponents/Users';

import HomeHeader from "./subcomponents/HomeHeader";
import HomeFooter from "./subcomponents/HomeFooter";
import UserDropdown from "./subcomponents/UserDropdown";

const SecureDashBoard = () => {

    const [userAccDropdown, setUserAccDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const handleClick = (event) => {
        if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
          return;
        }
        setUserAccDropdown(false);
      };
    
      useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
          document.removeEventListener("click", handleClick);
        };
      }, []);

    return (
        <div className="page">

            <HomeHeader menuArray = {[]}>
                <span className="icon"><img src="/status_icons/search-icon.png" alt="Search"/></span>
                <span className="icon"><img src="/status_icons/language-icon.png" alt="Language"/></span>
                <span className="icon" ref={dropdownRef} onClick={() => setUserAccDropdown(prev=>!prev)}>
                    <img src="/status_icons/login-icon.png" alt="Login"/>
                    { userAccDropdown && (<UserDropdown/>)}                   
                </span>
            </HomeHeader>

            <main>
                <section>
                    <h2>Settings</h2>
                    <br />
                    <Users />
                    <br />
                    <div className='smallfont10'>
                        <div className='inline'>
                            <span >Go to HomePage?</span>
                            <span ><Link to="/">Home</Link></span>
                        </div>
                    </div>
                </section>
            </main>

            <HomeFooter/>

        </div>
        
    )
}

export default SecureDashBoard
