import Directory from './Directory';
import { useFolderContext } from "../../context/FolderContextProvider";

const FileExplorerSidePanel = () => {

    const {explorerJson} = useFolderContext();

    return (
        <section className='section7'>
                <section className='panel-title'><h5>PLACES</h5></section>
                <Directory folderJson={explorerJson}/>
        </section>
    )
}

export default FileExplorerSidePanel