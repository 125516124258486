import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import HomeHeader from "./subcomponents/HomeHeader";
import HomeFooter from "./subcomponents/HomeFooter";

const HomePage = () => {

    const [userAccDropdown, setUserAccDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const menuArray = ['Products', 'Solutions', 'Industries', 'Company'];


  const handleClick = (event) => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      return;
    }
    setUserAccDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

    return (
        <div className="page">

            <HomeHeader menuArray = {menuArray}>
                <span className="icon"><img src="/status_icons/search-icon.png" alt="Search"/></span>
                <span className="icon"><img src="/status_icons/language-icon.png" alt="Language"/></span>
                <span className="icon" ref={dropdownRef} onClick={() => setUserAccDropdown(prev=>!prev)}>
                    <img src="/status_icons/login-icon.png" alt="Login"/>
                    { userAccDropdown && (
                    <div className="dropdown-content">
                        <div> <Link to="/login">Login</Link> </div>
                        <div> <Link to="/register">Register</Link> </div>
                    </div>
                    )}                   
                </span>
            </HomeHeader>

            <main></main>

            <HomeFooter/>

        </div>
    )
}

export default HomePage
