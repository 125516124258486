import { useState, useRef, useEffect, useCallback } from "react";
import { useFolderContext } from "../../context/FolderContextProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { renameNode, isNameInChildNodes, deepCopy, updateNodeTables } from "./fileExplorer_func";

const TREE_URL = '/treenodes';

export default function FileExplorerRenameTile({isFolder, defaultname}) {

    const {explorerJson, handleExplorerStateChange, openDirectoryId, renameId, setRenameId } = useFolderContext();

    const [newName, setNewName] = useState(defaultname ?? 'untitled');
    const axiosPrivate = useAxiosPrivate();
    const newRef = useRef();

    const renameFunc2child = useCallback(() => {
        try {
            let nodeRename = {id: renameId, name: newName};
            updateNodeTables(axiosPrivate, 'put', TREE_URL, nodeRename).then(
                function(value) {
                    let newTree = deepCopy(renameNode(explorerJson, newName, renameId, openDirectoryId));
                    handleExplorerStateChange(newTree);
                    setRenameId(null);
                    console.log(value);
                },
                function(error) {
                    console.error(error);
                }
            )
        } catch (error) {
            console.error(error);
        }
    },[axiosPrivate, explorerJson, handleExplorerStateChange, newName, openDirectoryId, renameId, setRenameId])

    const renameFunc2 = useCallback((e) => {
        if (renameId && e.target.className === 'section8' && e.target.classList[0] !== 'new') {
            if (newName === defaultname) {setRenameId(null)}
            else if (! isNameInChildNodes(explorerJson, openDirectoryId, newName)) {
                renameFunc2child()
            }
            else {alert('same name exists !')}            
        }

        else if(renameId && e.target.id !== 'tool3' && e.target.classList[0] !== 'new') {
            setRenameId(null);
        }

        else if(renameId) {
            newRef.current.focus();
        }

    },[explorerJson, renameId, newName, openDirectoryId, setRenameId, defaultname, renameFunc2child])
   
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (! isNameInChildNodes(explorerJson, openDirectoryId, newName)) {
                renameFunc2child()                             
            }
            else {alert('same name exits !')}
          }
    }
    
    const itemType = isFolder ? '/status_icons/folder_icon.svg' : '/status_icons/file_icon.svg';

    useEffect(() => {
        // Focus the input element when the component mounts
        newRef.current.focus();
    
        // Select the text in the input
        newRef.current.select();
    }, []);

    useEffect(() => {
        document.addEventListener('click', renameFunc2);
      
        return () => {
          document.removeEventListener('click', renameFunc2);
        }
      }, [renameFunc2])

    return (
        <div className="new items">
            <div className='new tile-top'>
                <img className="new tileIcon" src={itemType} alt={isFolder ? 'Folder Icon' : 'File Icon'} width='50px' />
            </div>
            <div className='new tile-bottom'>
                <div>
                    <input
                    className="new"
                    id="new"
                    type="text"
                    ref={newRef}
                    onChange={(e) => setNewName(e.target.value)}
                    value={newName}
                    required
                    onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
        </div>
    )
}
