import '../stylecomponents/homeHeader.css'
const HomeHeader = (props) => {

    const { menuArray, children } = props;
    const navMenu = menuArray.map((elem,i) => <li key={i}><a href={'#'+ elem}>{elem}</a></li>)

    return (
        <header>
            <div className="inner-element">
                <div className="header-logo">
                    <img src="/status_icons/zoptim-logo2.png" alt="Zoptim" width="80%"/>
                </div>
                <div className="header-menu">
                    <nav>
                        <ul>
                            {navMenu}
                        </ul>
                    </nav>
                </div>
                <div className="header-icons">
                    {children}
                </div>
            </div>
        </header>
    )
}

export default HomeHeader