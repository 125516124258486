import { Link } from "react-router-dom";
import HomeHeader from "./subcomponents/HomeHeader";
import HomeFooter from "./subcomponents/HomeFooter";

const Missing = () => {

    return (
        <div className="page">

            <HomeHeader menuArray = {[]}></HomeHeader>

            <main>
            <h2>Oops!</h2>
            <p>Page Not Found</p>

            <br />
                    <div className='smallfont10'>
                        <div className='inline'>
                            <span >Go to HomePage?</span>
                            <span ><Link to="/">Home</Link></span>
                        </div>
                    </div>
            </main>

            <HomeFooter/>

        </div>
    )
}

export default Missing
