import { Link } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from '../api/axios';

import HomeHeader from "./subcomponents/HomeHeader";
import HomeFooter from "./subcomponents/HomeFooter";
import CountryList from "./data/CountryList";

import './stylecomponents/reg.css'

const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/register';



const Register = () => {

    const userRef = useRef();
    const errRef = useRef();

    const [usermail, setUsermail] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [password, setPassword] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [salutation, setSalutation] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');


    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(EMAIL_REGEX.test(usermail));
    }, [usermail])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(password));
        setValidMatch(password === matchPwd);
    }, [password, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [usermail, password, matchPwd, salutation, firstname, lastname, company, phone, country])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = EMAIL_REGEX.test(usermail);
        const v2 = PWD_REGEX.test(password);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ usermail, password, salutation, firstname, lastname, company, phone, country }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            // TODO: remove console.logs before deployment
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response))
            setSuccess(true);
            //clear state and controlled inputs
            setUsermail(''); setPassword(''); setMatchPwd(''); setSalutation(''); setFirstname(''); setLastname('');
            setCompany(''); setPhone(null); setCountry('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed')
            }
            errRef.current.focus();
        }
    }


    return (
        <div className="page">

            <HomeHeader menuArray = {[]}></HomeHeader>

            <main>

                {success ? (
                <section>
                    <h2 className="sec-child"> Registered successfully! </h2>
                    <div className="smallfont10 sec-child">
                        <div className='inline'>
                            <span >Already Registered?</span> 
                            <span ><Link to="/login">Login</Link></span>              
                        </div>
                        <div className='inline'>
                            <span >Go to HomePage?</span>
                            <span ><Link to="/">Home</Link></span>
                        </div>
                    </div>
                </section>

                ) : ( 
                
                <>

                <section className="section3">
                    
                    <h2 className="sec-child">Register</h2>

                    <form className="sec-child" onSubmit={handleSubmit}>

                        <fieldset>
                            <legend>Personal</legend>
                            <div>
                                <select id="salutation" placeholder="Salutations" value={salutation} onChange={e=>setSalutation(e.target.value)} required>
                                    <option value="" disabled >Salutation</option><option value="Ms.">Ms.</option><option value="Mr.">Mr.</option><option value={null}>Rather not say</option>
                                </select>
                                <input
                                    type="text"
                                    id="firstname"
                                    placeholder="First Name"
                                    ref={userRef}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    value={firstname}
                                    minLength={3}
                                    autoFocus
                                    required
                                />
                                <input
                                    type="text"
                                    id="lastname"
                                    placeholder="Last Name"
                                    onChange={(e) => setLastname(e.target.value)}
                                    value={lastname}
                                    minLength={3}
                                    required
                                />
                            </div>
                            <div>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Email"
                                    onChange={(e) => setUsermail(e.target.value)}
                                    value={usermail}
                                    required
                                    onFocus={() => setUserFocus(true)}
                                    onBlur={() => setUserFocus(false)}
                                />
                            </div>

                        </fieldset>

                        <fieldset>
                            <legend>Password</legend>
                            <div>
                                <input
                                    type="password"
                                    id="password"
                                    placeholder="Password"
                                    autoComplete="off"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                    onFocus={() => setPwdFocus(true)}
                                    onBlur={() => setPwdFocus(false)}
                                />
                                <input
                                    type="password"
                                    id="confirm_pwd"
                                    placeholder="Confirm Password"
                                    autoComplete="off"
                                    onChange={(e) => setMatchPwd(e.target.value)}
                                    value={matchPwd}
                                    required
                                    onFocus={() => setMatchFocus(true)}
                                    onBlur={() => setMatchFocus(false)}
                                />
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>Company</legend>
                            <div>
                                <input
                                    type="text"
                                    id="companyname"
                                    placeholder="Company"
                                    onChange={(e) => setCompany(e.target.value)}
                                    value={company}
                                    minLength={3}
                                    required
                                />
                            </div>
                            <div>
                                <input
                                    type="tel"
                                    id="phone"
                                    placeholder="Phone"
                                    pattern="[0-9]{10}"
                                    onChange={(e) => setPhone(e.target.value)}
                                    value={phone}
                                    minLength={7}
                                    required
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    id="country_region"
                                    value="Country/Region"
                                    disabled
                                    required
                                />
                                <select id="country" placeholder="" value={country} onChange={e=>setCountry(e.target.value)} required>
                                    <CountryList/>
                                </select>    
                            </div>
                        </fieldset>

                        <p className="smallfont10">Zoptim will use the data provided hereunder in accordance with the Privacy Statement.</p>

                        <div className="smallfont10">
                            <input type="checkbox" className="box" id='term_condition_box'/>
                            <label htmlFor='term_condition_box'>I have read the terms & conditions and hereby confirm that it will govern my use of this Zoptim software.</label>
                        </div>

                        <button>Sign Up</button>
 

                    </form>

                    <div className="smallfont10 sec-child">
                        <div className='inline'>
                            <span >Already Registered?</span> 
                            <span ><Link to="/login">Login</Link></span>              
                        </div>
                        <div className='inline'>
                            <span >Go to HomePage?</span>
                            <span ><Link to="/">Home</Link></span>
                        </div>
                    </div>                        
                        
                </section>

                <section className='section4'>
                    <p ref={errRef} className={errMsg ? "errmsg sec-child" : "offscreen sec-child"} >
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {' ' + errMsg + ' ...'}
                    </p>

                    <div className="sec-child">
                        <FontAwesomeIcon icon={faCheck} className={userFocus && validName ? "valid" : "hide"} />
                        <p className={userFocus && usermail && validName ? "instructions" : "offscreen"}>Valid email address</p>
                    </div>

                    <div className="sec-child">
                        <p id="uidnote" className={userFocus && usermail && !validName ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Invalid email address
                        </p>
                    </div>

                    <div className="sec-child">
                        <FontAwesomeIcon icon={faCheck} className={(pwdFocus && validPwd) ? "valid" : "hide"} />
                        <p className={pwdFocus && validPwd ? "instructions" : "offscreen"}>Valid Password</p>
                    </div>

                    <div className="sec-child">
                        <p id="pwdnote" className={pwdFocus && password && !validPwd ? "instructions" : "offscreen"}>    
                            <FontAwesomeIcon icon={faInfoCircle} />
                            8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters: <span >!</span> <span >@</span> <span >#</span> <span >$</span> <span >%</span>
                        </p>
                    </div>

                    <div className="sec-child">
                        <FontAwesomeIcon icon={faCheck} className={matchFocus && (validMatch && matchPwd) ? "valid" : "hide"} />
                        <p className={matchFocus && (validMatch && matchPwd) ? "instructions" : "offscreen"}>Password Matched</p>
                    </div>

                    <div className="sec-child">
                        <p id="confirmnote" className={matchFocus && matchPwd && !validMatch ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must match the first password input field.
                        </p>
                    </div>
                </section> 
                </>

                )}

            </main>

            <HomeFooter/>

        </div>
    )
}

export default Register